<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12">
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSetting.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="showAddForm"
          >
            Add User
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2 text-center"
        :per-page="tableSetting.perPage"
        :current-page="tableSetting.currentPage"
        :items="myProvider"
        :fields="tableSetting.fields"
        :sort-by.sync="tableSetting.sortBy"
        :sort-desc.sync="tableSetting.sortDesc"
        :sort-direction="tableSetting.sortDirection"
        :filter="tableSetting.filter"
        :filter-included-fields="tableSetting.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{
            tableSetting.currentPage * tableSetting.perPage -
              tableSetting.perPage +
              (data.index + 1)
          }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active === 1 ? "Yes" : "No" }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              variant="success"
              size="sm"
              style="margin-right: 2px"
              @click="showEditForm(row.item)"
            >
              Update
            </b-button>
            <b-button
              v-b-modal.modal-lg
              variant="success"
              size="sm"
              style="margin-right: 2px"
              @click="assignUser(row.item)"
            >
              {{ row.item.role === "user" ? "Assign Dealer" : "Assign Area" }}
            </b-button>
            <b-button
              v-b-modal.modal-lg
              size="sm"
              @click="showCommentsModal(row.item.id)"
            >
              View comments
            </b-button>
          </div>
        </template>
        <template #cell(is_edit)="row">
          <div class="text-nowrap">
            <b-form-checkbox
              v-model="row.item.is_update"
              :name="`check-id${row.item.id}`"
              size="lg"
              value="1"
              unchecked-value="0"
              :disabled="row.item.role === 'abm'"
              @change="checkIsEnabledConfirm(row.item)"
            />
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSetting.perPage"
              :options="tableSetting.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSetting.currentPage"
            :total-rows="tableSetting.totalRows"
            :per-page="tableSetting.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-user-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="handleOk"
      @show="handleShow"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="email"
            vid="email"
            rules="required|email|max:100"
          >
            <b-form-group>
              <label for="email">Email</label>
              <b-input
                id="email"
                v-model="user.email"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter email"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="first name"
            vid="first_name"
            rules="required|max:50"
          >
            <b-form-group>
              <label for="first_name">First Name</label>
              <b-input
                id="first_name"
                v-model="user.first_name"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter first name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="middle name"
            vid="middle_name"
            rules="required|max:50"
          >
            <b-form-group>
              <label for="last_name">Middle Name</label>
              <b-input
                id="middle_name"
                v-model="user.middle_name"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter middle name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="last name"
            vid="last_name"
            rules="required|max:50"
          >
            <b-form-group>
              <label for="last_name">Last Name</label>
              <b-input
                id="last_name"
                v-model="user.last_name"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter last name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="password"
            vid="password"
            :rules="passwordRule"
          >
            <b-form-group>
              <label
                for="password"
              >Password
                <span
                  v-if="editMode"
                >(Leave blank when not changing password)</span></label>
              <b-input
                id="password"
                v-model="user.password"
                type="password"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter password"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="password confirmation"
            vid="password_confirmation"
            :rules="passwordConfirmationRule"
          >
            <b-form-group>
              <label
                for="password_confirmation"
              >Password Confirmation
                <span
                  v-if="editMode"
                >(Leave blank when not changing password)</span></label>
              <b-input
                id="password_confirmation"
                v-model="user.password_confirmation"
                type="password"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter password confirmation"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="password confirmation"
            vid="role"
            rules="required"
          >
            <b-form-group>
              <label for="role">Role</label>
              <b-select
                id="role"
                v-model="user.role"
                :options="roles"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="Select Role"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>
          <div
            v-if="editMode && user.id !== 1"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="user.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              :disabled="isBusy"
            >
              Is Active?
            </b-form-checkbox>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="isBusy"
          @click="ok()"
        >
          {{ editMode ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-assign-area"
      scrollable
      no-close-on-backdrop
      size="lg"
      title="Assign Area"
    >
      <b-col cols="12">
        <b-form-group>
          <label>Search String</label>
          <b-input
            v-model="tableSettings4.filter"
            type="text"
            placeholder="Enter search string"
            debounce="1000"
          />
        </b-form-group>
      </b-col>
      <b-table
        ref="table4"
        hover
        responsive
        class="mt-2"
        :fields="tableSettings4.fields"
        :items="assignUsersArea"
        :per-page="tableSettings4.perPage"
        :current-page="tableSettings4.currentPage"
        :sort-by.sync="tableSettings4.sortBy"
        :sort-desc.sync="tableSettings4.sortDesc"
        :sort-direction="tableSettings4.sortDirection"
        :filter="tableSettings4.filter"
        :filter-included-fields="tableSettings4.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{
            tableSettings4.currentPage * tableSettings4.perPage -
              tableSettings4.perPage +
              (data.index + 1)
          }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active === 1 ? "Yes" : "No" }}
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-form-checkbox
              v-model="row.item.checked"
              size="lg"
              value="true"
              unchecked-value="false"
              @change="onConfirmCheckArea(row.item)"
            />
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings4.perPage"
              :options="tableSettings4.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings4.currentPage"
            :total-rows="tableSettings4.totalRows"
            :per-page="tableSettings4.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="modal-assign-dealer"
      scrollable
      no-close-on-backdrop
      size="lg"
      title="Assign Dealer"
    >
      <b-row class="mb-2">
        <b-col cols="12">
          <b-form-group>
            <label for="areas">Filter By Area</label>
            <v-select
              id="areas"
              v-model="selectedArea"
              placeholder="Choose Area"
              :options="areas"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        ref="table2"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings2.perPage"
        :current-page="tableSettings2.currentPage"
        :items="fetchDealer"
        :fields="tableSettings2.fields"
        :sort-by.sync="tableSettings2.sortBy"
        :sort-desc.sync="tableSettings2.sortDesc"
        :sort-direction="tableSettings2.sortDirection"
        :filter="tableSettings2.filter"
        :filter-included-fields="tableSettings2.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{
            tableSettings2.currentPage * tableSettings2.perPage -
              tableSettings2.perPage +
              (data.index + 1)
          }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active === 1 ? "Yes" : "No" }}
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-form-checkbox
              v-model="selected"
              size="lg"
              :value="row.item.id"
              @change="onConfirmCheck(row.item)"
            />
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings2.perPage"
              :options="tableSettings2.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings2.currentPage"
            :total-rows="tableSettings2.totalRows"
            :per-page="tableSettings2.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-show-comments"
      scrollable
      no-close-on-backdrop
      size="xl"
      title="Comments/Remarks"
    >
      <b-row class="mb-2">
        <b-col cols="12">
          <b-form-group>
            <label for="dealer">Filter By Dealer</label>
            <v-select
              id="dealer"
              v-model="selectedDealer"
              placeholder="Choose Dealer"
              :options="dealers"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        ref="table3"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings3.perPage"
        :current-page="tableSettings3.currentPage"
        :items="list_comment"
        :fields="tableSettings3.fields"
        :sort-by.sync="tableSettings3.sortBy"
        :sort-desc.sync="tableSettings3.sortDesc"
        :sort-direction="tableSettings3.sortDirection"
        :filter="tableSettings3.filter"
        :filter-included-fields="tableSettings3.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{
            tableSettings3.currentPage * tableSettings3.perPage -
              tableSettings3.perPage +
              (data.index + 1)
          }}
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings3.perPage"
              :options="tableSettings3.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings3.currentPage"
            :total-rows="tableSettings3.totalRows"
            :per-page="tableSettings3.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import {
  AxiosError,
  SharedUserService,
  MarketingOfficerDealerService,
  AssignDealerService,
  AssignAreaService,
  AssignEnabledEdit,
  ListService
} from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'MarketingUserAccounts',

  middleware: ['auth', 'marketing-officer'],

  metaInfo () {
    return {
      title: 'User Accounts'
    }
  },

  mixins: [formatter],

  data () {
    return {
      editMode: false,
      isBusy: false,
      selected: [],
      selectedIsEnabled: 0,
      areas: [],
      checkAreasByUser: [],
      selectedArea: [],
      dealers: [],
      selectedDealer: [],
      commentUserId: 0,
      area_id: null,
      user_id: null,
      roles: [
        { text: 'DCP', value: 'user' },
        { text: 'ABM', value: 'abm' }
      ],
      user: {
        id: '',
        active: 0,
        email: '',
        password: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        role: 'user'
      },
      tableSetting: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'is_edit',
          'actions',
          { key: 'role', sortable: true },
          {
            key: 'created_at',
            formatter: this.dateTimeFormatter,
            sortable: true
          },
          { key: 'email', sortable: true },
          { key: 'first_name', sortable: true },
          { key: 'middle_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'active', label: 'IS ACTIVE?' },
          {
            key: 'updated_at',
            formatter: this.dateTimeFormatter,
            sortable: true
          }
        ]
      },
      tableSettings2: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { key: 'area_name', sortable: true },
          { key: 'dealer_code', sortable: true },
          { key: 'dealer_name', sortable: true },
          'actions'
        ]
      },
      tableSettings4: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'area_name', label: 'Area Name', sortable: true },
          'actions'
        ]
      },
      list_dealer: [],
      tableSettings3: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { key: 'dealer_name', sortable: true },
          { key: 'comment', sortable: true },
          { key: 'type', sortable: true },
          { key: 'created_at', sortable: true },
          { key: 'delivery_receipt', sortable: true }
        ]
      },
      list_comment: []
    }
  },

  computed: {
    modalTitle () {
      return this.editMode ? 'Edit User' : 'Add User'
    },

    passwordRule () {
      return `${
        this.editMode ? '' : 'required|'
      }min:8|confirmed:password_confirmation`
    },

    passwordConfirmationRule () {
      return `${this.editMode ? '' : 'required'}`
    }
  },

  watch: {
    selectedArea (item) {
      this.area_id = item.value
      this.$refs.table2.refresh()
    },
    selectedDealer (item) {
      this.showComments(item.value)
    }
  },
  mounted () {
    core.index()
    this.getAreas()
  },

  methods: {
    async getAreas () {
      await ListService.getAreas().then(({ data }) => {
        this.areas = data.map(el => {
          return { value: el.id, label: el.area_name }
        })
      })
    },

    async getDealersByDcp (userId) {
      await ListService.getDealersByDcp(`user_id=${userId}`).then(
        ({ data }) => {
          this.dealers = data.map(el => {
            return { value: el.dealer.id, label: el.dealer.dealer_name }
          })
        }
      )
    },

    async showComments (dealerId) {
      await SharedUserService.getComment(
        `dealer_id=${dealerId}&user_id=${this.commentUserId}`
      ).then(({ data }) => {
        this.list_comment = data.items
      })
    },

    async showCommentsModal (userId) {
      this.$bvModal.show('modal-show-comments')
      this.commentUserId = userId
      this.getDealersByDcp(userId)
    },

    async assignUser (item) {
      this.user.id = item.id
      this.area_id = null
      if (item.role === 'user') {
        this.assignUsersDealer(item)
      } else {
        this.$bvModal.show('modal-assign-area')
        // this.assignUsersArea(item)
      }
    },

    async onConfirmCheckArea (data) {
      await this.$swal.fire({
        title: 'Are you sure you want to select this area?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Submit',
        cancelButtonColor: '#FF2929',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.checkArea(data)
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async checkArea ({ user_id, id }) {
      await AssignAreaService.post({ user_id, area_id: id })
        .then(response => {
          this.$swal
            .fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            })
            .then(() => {
              this.$refs.table.refresh()
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
    async assignUsersArea (ctx) {
      try {
        const { data } = await AssignAreaService.get(`user_id=${this.user.id}&page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}`)

        this.tableSettings4.totalRows = data.total_rows
        return data.items
      } catch (error) {
        return []
      }
    },

    async assignUsersDealer (item) {
      await AssignDealerService.get(item.id).then(({ data }) => {
        const res = data?.map(el => {
          if (el.checked) {
            return el.id
          }
        })
        this.selected = res
        this.$bvModal.show('modal-assign-dealer')
      })
    },

    showAddForm () {
      this.editMode = false
      this.$bvModal.show('modal-user-form')
    },

    showEditForm (item) {
      this.editMode = true
      this.user.id = item.id
      this.user.active = item.active
      this.user.email = item.email
      this.user.password = ''
      this.user.password_confirmation = ''
      this.user.first_name = item.first_name
      this.user.middle_name = item.middle_name
      this.user.last_name = item.last_name
      this.user.role = 'user'
      this.$bvModal.show('modal-user-form')
    },

    handleOk (bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.editMode) {
        this.confirmPutSubmit()
      } else {
        this.confirmStoreSubmit()
      }
    },

    handleShow () {
      if (this.editMode === false) {
        this.user.id = 0
        this.user.active = 1
        this.user.email = ''
        this.user.password = ''
        this.user.password_confirmation = ''
        this.user.first_name = ''
        this.user.middle_name = ''
        this.user.last_name = ''
      }
    },

    async fetchDealer (ctx) {
      try {
        const { data } = await MarketingOfficerDealerService.get(`filter_area=${this.area_id}&page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}`)

        this.tableSettings2.totalRows = data.total_rows
        return data.items
      } catch (error) {
        return []
      }
    },

    async myProvider (ctx) {
      try {
        const { data } = await SharedUserService.getUserDcp(
          `page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}`
        )
        this.tableSetting.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async confirmStoreSubmit () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            title: 'Do you really want to create user?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Submit',
            cancelButtonColor: '#FF2929',
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onStoreSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        }
      })
    },

    async onStoreSubmit () {
      this.isBusy = true
      await SharedUserService.post(this.user)
        .then(response => {
          this.$bvModal.hide('modal-user-form')
          this.isBusy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
          this.$refs.table.refresh()
        })
        .catch(error => {
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
            }
          }
          this.isBusy = false
        })
    },

    async confirmPutSubmit () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            title: 'Do you really want to save any changes you made?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Submit',
            cancelButtonColor: '#FF2929',
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onPutSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        }
      })
    },

    async onPutSubmit () {
      this.isBusy = true
      await SharedUserService.put(this.user)
        .then(response => {
          this.$bvModal.hide('modal-user-form')
          this.isBusy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
          this.$refs.table.refresh()
        })
        .catch(error => {
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
            }
          }
          this.isBusy = false
        })
    },

    async onConfirmCheck (item) {
      const userDealer = {
        dealer_id: item.id,
        user_id: this.user.id
      }

      await this.$swal.fire({
        title: 'Are you sure want to select this dealer ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Update',
        cancelButtonColor: '#FF2929',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.checkDealer(userDealer)
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async checkDealer (userDealer) {
      await AssignDealerService.post(userDealer)
        .then(response => {
          this.$swal
            .fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            })
            .then(() => {
              this.$refs.table.refresh()
            })
        })
        .catch(error => {
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
            }
          }
          this.isBusy = false
        })
    },

    async checkIsEnabledConfirm (item) {
      const userIsEditEnabled = {
        user_id: item.id
      }
      if (item.role === 'user') {
        await this.$swal.fire({
          title: 'Are you sure want to select this user to edit their product quantity ?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Confirm',
          cancelButtonColor: '#FF2929',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return this.checkIsEnabled(userIsEditEnabled)
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        })
      } else {
        this.$swal
          .fire({
            icon: 'warning',
            title: 'Warning',
            text: 'You are not allowed to edit this user. ',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
      }
    },

    async checkIsEnabled (userIsEditEnabled) {
      await AssignEnabledEdit.put(userIsEditEnabled)
        .then(response => {
          this.$swal
            .fire({
              icon: 'success',
              title: 'Success',
              text: response.data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            })
            .then(() => {
              this.$refs.table.refresh()
            })
        })
        .catch(error => {
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
            }
          }
          this.isBusy = false
        })
    }
  }
}
</script>
